.container {
    position: relative;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform .2s linear;
    color: var(--primary_dark);
    cursor: pointer;
    background-color: var(--primary_lighter);
    border-radius: 1000px;
    font-weight: bold;
    box-shadow: 0px 0px 10px var(--shadow);
}

.wrapper:hover {
    transform: scale(1);
}

.picture {
    width: inherit;
    height: inherit;
    border-radius: inherit;
    object-fit: cover;
}