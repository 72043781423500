.container {
    border-radius: 5px;
    position: relative;
    cursor: pointer;
    box-shadow: 0px 0px 2px var(--destaque);
}

.wrapper {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.header {
    border: 1px solid var(--destaque);
    background-color: var(--background);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 5px 5px 0px 0px;    
    background-repeat: no-repeat;
    background-size: cover;
}

.layer {
    padding: 30px 30px;
    width: 100%;
    height: 100%;
    background-color: var(--backgroundOpac50);
}

.body {
    padding: 30px;
}

.photo {
    display: flex;
    align-items: center;
    gap: 15px;
}

.name {
    flex: 1 0;
}

.role {
    font-size: 12px;
    color: var(--texto2);
}

.about {
    margin-bottom: 25px;
    font-size: 12px;
    color: var(--texto2);
    overflow: auto;
}

.label {
    font-size: 12px;
    color: var(--texto2);
    margin-bottom: 5px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.value {
    font-size: 14px;
    color: var(--texto1);
    max-width: 135px;
}

.email {
    margin-bottom: 30px;
    flex: 1 0;
}

.distance {
    margin-bottom: 30px;
    flex: 1 0;
}

.score {
    margin-bottom: 30px;
    flex: 1 0;
}

.starList {
    display: flex;
    gap: 3px;
}

.star {
    padding: 0px 0px;
    font-size: 20px;
    color: var(--texto3);
}

.starActive {
    padding: 0px 0px;
    font-size: 20px;
    color: #FFC000;
    transition: color 0.2s ease;
}

.nomeEmail {
    display: flex;
    align-items: center;
}

.phoneLocation {
    display: flex;
    justify-content: space-between;
}

.distanceScore {
    display: flex;
    justify-content: space-between;
}

.phone {
    margin-bottom: 25px;
    flex: 1 0;
}

.location {
    margin-bottom: 25px;
    flex: 1 0;
}

.social {
    text-align: center;
    min-height: 45px;
}

.actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

@media screen and (max-width: 768px) {
    .container {
        max-width: 100%;
    }

    .actions {
        gap: 0px;
    }

}