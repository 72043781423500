.container {}

.wrapper {
    background: var(--destaque);
    padding: 25px;
    border-radius: 10px;
}

.title {
    color: var(--texto1);
    font-size: 14px;
}

.infos {
    padding: 25px 0px 5px 0px;
    display: flex;
}

.value {
    color: var(--texto1);
    font-weight: bold;
    font-size: 2rem;
}

.graph {}