.container {
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
}

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
}

.text {
    color: var(--texto1);
    font-weight: bold;
    font-size: 18px;
}

.logo {
    width: 60px;
    height: 60px;
}

.icon {
    font-size: 30px;
    color: var(--primary_main);
    animation: spin 1.5s infinite linear;
}

.alert {
    color: var(--yellow);
    font-size: 30px;
}

.error {
    font-weight: bold;
    color: var(--texto1);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}