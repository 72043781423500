.container {}

.wrapper {}

.tabs {
    display: flex;
    align-items: center;
    gap: 30px;
}

.content {
    margin: 30px 0px
}

.tab {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--texto2);
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
    padding: 0px 5px 10px 5px;
}

.active {
    color: var(--texto1);
    border-bottom: 2px solid var(--primary_main);
}

@media screen and (max-width: 768px) {

    .tabs {
        gap: 20px;
    }

    .tabIcon {
        font-size: 18px;
    }

    .tabTitle {
        display: none;
    }
}