.container {
    border-radius: 5px;
    overflow: auto;
    max-height: 100vh;
}

.wrapper {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.header {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    background-color: var(--background);
    padding: 30px;
    border-radius: 5px;
}

.headerInfos {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
}

.headerName {
    font-size: 22px;
    color: var(--texto1);
    display: flex;
    align-items: center;
    gap: 5px;
}

.headerRole {
    font-size: 16px;
    color: var(--texto2);
}

.headerLeft {
    display: flex;
    gap: 25px;
}

.headerRight {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.starGroup {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
}

.star {
    padding: 0px 3px;
    font-size: 20px;
    color: var(--texto3);
    transition: color 0.2s ease;
    cursor: pointer;
}

.starActive {
    padding: 0px 3px;
    font-size: 20px;
    color: #FFC000;
    transition: color 0.2s ease;
    cursor: pointer;
}

.star:hover,
.star:hover~.star,
.star.clicked,
.star.clicked~.star {
    color: #FFC000;
    /* or any other color you want */
}

.aval {
    font-size: 14px;
    color: var(--texto1);
}

.content {
    display: flex;
    gap: 20px;
}

.leftPanel {
    flex: 0 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.rightPanel {
    flex: 1 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.line {
    border-left: 1px solid var(--border);
    height: 60%;
}

.statics {
    background-color: var(--background);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
}

.staticsInfo {
    flex: 1 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 30px 30px;
}

.staticsLabel {
    font-size: 14px;
    color: var(--texto2);
}

.staticsValue {
    font-size: 22px;
    color: var(--texto1);
    display: flex;
    align-items: baseline;
}

.infos {
    background-color: var(--background);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 5px;
    padding: 30px 30px;
}

.infosTitle {
    color: var(--texto1);
    font-weight: bold;
    font-size: 14px;
}

.infosContent {
    min-width: 400px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.infosContentSocial {
    min-width: 400px;
    margin-top: 20px;
    display: flex;
    gap: 12px;
}

.infosValue {
    color: var(--texto1);
    font-size: 14px;
    max-width: 600px;
    line-height: 25px;
}

.infosIcon {
    padding-right: 12px;
    min-width: 20px;
}

@media screen and (max-width: 1024px) {

    .container {
        overflow: auto;
    }

    .wrapper {
        height: auto;
        padding: 30px 0px;
    }

    .header {
        flex-direction: column;
    }

    .headerLeft {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .headerRight {
        align-items: center;
        justify-content: center;
    }

    .infos {
        padding: 20px;
    }

    .staticsInfo {
        padding: 10px;
    }

    .content {
        flex-direction: column;
    }

    .leftPanel {
        max-width: auto;
        min-width: 100%;
    }

    .rightPanel {
        max-width: auto;
        min-width: 100%;
    }

    .infosContent {
        min-width: auto;
    }

    .infosValue {
        font-size: 12px;
    }

    .infosContentSocial {
        min-width: auto;
        gap: 0px;
    }
}