.container {
    margin: 0px 25px;
}

.dashboards {
    display: flex;
    margin-top: 30px;
    gap: 30px;
}

.dash1 {
    flex: 1 0;
}

.dash2 {
    flex: 2 0;
}

@media screen and (max-width: 768px) {
    .dashboards {
        flex-direction: column;
    }
}