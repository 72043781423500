.container {
    cursor: pointer;
}

.wrapper {
    position: relative;
}

.label {
    left: 11px;
    position: absolute;
    padding: 0px 7px;
    font-size: 14px;
    font-weight: bold;
    transition: top 50ms linear;
    z-index: 1;
    border-radius: 5px;
}

.input {
    position: inherit;
    object-fit: contain;
    padding: 16px 14px;
    font-size: 16px !important;
    border-radius: 5px;
    resize: none;
    height: 20px;
}

.value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 20px;
}

.value svg {
    color: var(--texto2);
    transition: transform 0.2s linear;
    font-size: 14px;
}

.list {
    left: 0px;
    right: 0px;
    top: calc(100% + 10px);
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: var(--destaque);
    padding: 14px 0px;
    z-index: 5;
    border-radius: 5px;
    box-shadow: 0px 0px 5px var(--background);
    max-height: 400px;
    overflow: auto;
}

.item {
    min-height: 20px;
    padding: 10px 10px;
    cursor: pointer;
}

.item:hover {
    background-color: var(--background);
}

.input:focus {
    outline: none;
}