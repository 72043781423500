.container {
    z-index: 6;
    position: absolute;
    background-color: var(--destaque);
    border-radius: 5px;
    top: calc(100% + 12px);
    right: 0px;
    box-shadow: 0px 0px 5px var(--shadow);
}

.arrow {
    position: absolute;
    right: 14px;
    top: -6px;
    border: solid var(--destaque);
    border-width: 0 10px 10px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
}
