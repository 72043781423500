.container {
    position: absolute;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    border-radius: 100px;
    pointer-events: none;
}

.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 38px;
    color: var(--texto1);
    border-radius: inherit;
}

.spin {
    animation: spin 1.5s infinite linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}