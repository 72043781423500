.container {
    max-width: 300px;
    min-width: 300px;
    border-right: 1px solid var(--border);
    background-color: var(--background);
    min-height: 100vh;
    max-height: 100vh;
    overflow: auto;
}

.wrapper {
    position: relative;
    top: 0px;
    height: 100%;
    padding: 20px 30px;
    overflow: auto;
}

.logo {
    width: 40px;
    height: 40px;
}

.profileCard {
    margin: 35px 0px;
    padding: 15px 20px;
    border-radius: 5px;
    background-color: var(--destaque);
    display: flex;
    align-items: center;
    gap: 15px;
}

.infos {
    font-size: 15px;
}

.infosName {
    color: var(--texto1);
    font-weight: bold;
    margin-bottom: 5px;
}

.infosRole {
    color: var(--texto2)
}