.container {
    position: relative;
}

.alerts {
    z-index: 999;
    position: fixed;
    top: 30px;
    right: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}