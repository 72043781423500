.container {}

.infos {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    background-color: var(--destaque);
    border-radius: 5px;
    flex: 1 0;
    gap: 10px;
    padding: 10px 0px;
}


.block {
    background-color: var(--destaque);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.blockHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
}

.blockTitle {
    color: var(--texto2);
    font-weight: bold;
    font-size: 12px;
    min-width: 100px;
}

.blockBody {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}

.actions {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}