.container {}

.infos {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    background-color: var(--destaque);
    border-radius: 5px;
    flex: 1 0;
    gap: 10px;
    padding: 10px 0px;
}


.block {
    background-color: var(--destaque);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.blockHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
}

.blockTitle {
    color: var(--texto2);
    font-weight: bold;
    font-size: 12px;
    min-width: 100px;
}

.blockBody {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}

.grid {
    width: 100%;
}

.header {
    color: var(--texto2);
    font-weight: bold;
    border-radius: 5px 5px 0px 0px;
    border: 1px solid var(--background);
    background: var(--background);
}

.body {
    font-size: 14px;
    border: 1px solid var(--background);
    padding: 0px 0px;
    color: var(--texto1);
}

.row {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    align-items: center;
    padding: 15px 25px;
    gap: 20px;
}

.column {
    flex: 1 0;
    word-break: break-all;
}

.actions {
    font-size: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    min-width: 268px;
}

.columnEmpty {
    min-width: 50px;
}

.picture {
    width: 40px;
    border-radius: 50px;
}

.check {
    color: var(--primary_main);
    cursor: pointer;
}

.cancel {
    color: var(--texto2);
    cursor: pointer;
}

.emptyList {
    padding: 25px;
}

.footer {
    border: 1px solid var(--background);
    background: var(--background);
    min-height: 30px;
    border-radius: 0px 0px 5px 5px;
    color: var(--texto2);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    font-weight: bold;
    padding: 0px 25px;
}