.container {
    padding: 15px 0px 15px 0px;
    min-width: 360px;
    max-width: 360px;
}

.wrapper {
    color: var(--texto1);
    font-weight: bold;
    background-color: var(--destaque);
    max-height: calc(100vh - 120px);
    overflow: auto;
}

.header {
    margin-bottom: 10px;
}

.header:after {
    margin: 10px 0px;
    display: block;
    content: '';
    height: 1px;
    background-color: var(--texto3);
}

.title {
    padding: 0px 15px;
    font-size: 15px;
}

.notification {
    padding: 10px 20px 10px 15px;
    font-weight: normal;
    font-size: 14px;
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.avatarText {
    display: flex;
    align-items: center;
    gap: 10px;
}

.time {
    margin-top: 5px;
    font-size: 12px;
    color: var(--texto2);
}

.read {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    min-width: 12px;
    font-size: 12px;
    color: var(--primary_main);
}

@media screen and (max-width: 425px) {
    .container {
        min-width: 330px;
        max-width: 330px;
    }
}

@media screen and (max-width: 375px) {
    .container {
        min-width: 280px;
        max-width: 280px;
    }
}

@media screen and (max-width: 320px) {
    .container {
        min-width: 230px;
        max-width: 230px;
    }
}