.container {
    margin: 4px 0px;
    border-radius: 5px;
    color: var(--texto2);
}

.active {
    background-color: var(--transparent_foreground);
    color: var(--primary_main) !important;
}

.wrapper {
    padding: 13px 15px;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}

.wrapper:hover {
    border-radius: 5px;
    background-color: var(--destaque);
}

.icon {
    min-width: 20px;
    font-size: 16px;
}

.title {
    font-size: 15px;
    font-weight: bold;
}