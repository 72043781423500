.container {
    color: var(--texto1);
    width: 80vw;
    margin: auto;
}

.wrapper {
    width: 100%;
    overflow: auto;
    max-height: 600px;
}