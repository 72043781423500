.container {}

.wrapper {
    position: relative;
    cursor: text;
}

.label {
    left: 11px;
    position: absolute;
    padding: 0px 7px;
    font-size: 14px;
    font-weight: bold;
    transition: top 50ms linear;
    z-index: 1;
    border-radius: 5px;
}

.input {
    position: inherit;
    object-fit: contain;
    width: 100%;
    padding: 16px 35px 16px 14px;
    font-size: 16px !important;
    border-radius: 5px;
    resize: none;
}

.input:focus {
    outline: none;
}

.input::placeholder {
    color: var(--texto2);
    opacity: 1;
}

.input:-ms-input-placeholder {
    color: var(--texto2);
}

.input::-ms-input-placeholder {
    color: var(--texto2);
}

.clear {
    position: absolute;
    width: 20px;
    top: 20px;
    right: 12px;
    font-size: 14px;
    color: var(--texto2);
    transition: transform 0.1s linear;
}