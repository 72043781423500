.container {
    width: 100%;
}

.wrapper {
    display: flex;
    gap: 30px;
    width: 100%;
}

.infos {
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex: 1 0;
}

.history {
    flex: 1 0;
}

.block {
    background-color: var(--destaque);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.blockHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
}

.blockTitle {
    color: var(--texto2);
    font-weight: bold;
    font-size: 12px;
    min-width: 100px;
}

.blockAdd {
    display: flex;
    align-items: center;
    gap: 10px;
}

.blockBody {
    margin: 25px 0px 5px 0px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.star {
    color: var(--yellow);
}

.planInfos {
    margin: 10px 0px;
}

.plan {
    width: 100%;
    font-size: 24px;
    color: var(--texto1);
    font-weight: bold;
}

.planInfosLine {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    padding-bottom: 10px;
}

.planInfosLabel {
    color: var(--texto2);
}

.planInfosValue {
    color: var(--texto1);
}

.planDetails {
    font-size: 12px;
    color: var(--texto2);
    gap: 5px;
    margin-top: 5px;
}

.planHint {
    margin-top: 10px;
    font-size: 12px;
    color: var(--texto2);
}

.planSell {
    color: var(--texto1);
}

.planPrice {
    font-size: 28px;
    font-weight: bold;
    margin: 20px 0px;
}

.highlight {
    color: var(--primary_main);
}

.form {
    display: flex;
    align-items: center;
    width: 100%;
}


.list {
    display: flex;
    flex-direction: column;
    width: 100%;
    font-size: 12px;
}

.listHeader {
    color: var(--texto2) !important;
    font-weight: bold;
    padding-bottom: 15px;
}

.listRow {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    font-size: 12px;
    color: var(--texto1);
    margin-bottom: 5px;
}

.listColumn {
    flex: 1 0;
    display: flex;
    align-items: center;
}

.listColumnCenter {
    flex: 1 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.listColumnRight {
    flex: 1 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.listPages {
    display: flex;
    justify-content: center;
    margin: 20px 20px 0px 20px
}

.listPage {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: var(--texto2);
    font-weight: bold;
    font-size: 14px;
}

.listEmpty {
    color: var(--texto2);
    font-size: 14px;
}

.active {
    color: var(--green);
    font-weight: bold;
}

.cancelled {
    color: var(--red);
    font-weight: bold;
}

@media screen and (max-width: 768px) {

    .wrapper {
        flex-direction: column;
    }

    .blockHeader {
        gap: 10px;
    }

}