.container {}

.wrapper {
    display: flex;
    gap: 30px;
}

.upload {
    background-color: var(--destaque);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 80px 80px;
    height: 200px;
}

.uploadInfos {
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    font-size: 14px;
    color: var(--texto2);
    flex: 0 0;
}

.infos {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    background-color: var(--destaque);
    border-radius: 5px;
    flex: 1 0;
    gap: 10px;
    padding: 10px 0px;
}


.block {
    background-color: var(--destaque);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.blockHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
}

.blockTitle {
    color: var(--texto2);
    font-weight: bold;
    font-size: 12px;
    min-width: 100px;
}

.blockBody {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}

.field {
    flex: 1 0;
    padding: 0px 5px;
    flex-basis: calc(50% - 15px);
    padding: 10px 5px;
}

.textarea {
    padding: 0px 5px;
    flex-basis: 100%
}

.command {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.hintIcon {
    margin-right: 5px;
    color: var(--texto2);
}

.hint {
    margin: 10px 0px 0px 0px;
    color: var(--texto2);
    font-size: 12px;
}

@media screen and (max-width: 768px) {

    .wrapper {
        flex-direction: column;
    }

    .infos {
        flex-direction: column;
        align-items: center;
    }

    .field {
        width: 100%;
    }

    .textarea {
        width: 100%;
    }

    .command {
        margin-bottom: 20px;
    }

}