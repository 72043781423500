.container {}

.wrapper {
    background: var(--destaque);
    padding: 25px;
    border-radius: 10px;
}

.title {
    color: var(--texto1);
    font-size: 14px;
}

.infos {
    padding: 25px 0px 5px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.value {
    color: var(--texto1);
    font-weight: bold;
    font-size: 2rem;
}

.graph {}

.graph div {
    width: auto !important;
}

.pie {
    position: relative;
    min-height: 300px;
}

.pieFloat {
    position: absolute;
    top: 110px;
    left: 90px;
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.pieLabel {
    color: var(--texto2);
    font-size: 14px;
}

.pieValue {
    color: var(--texto1);
    font-weight: bold;
    font-size: 26px;
}

@media screen and (max-width: 768px) {
    .pie {}
}