.container {
    position: relative;
    cursor: pointer;
}

.wrapper {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform .2s;
}

.wrapper:hover {
    border-radius: 50px;
    background-color: var(--destaque);
    transform: scale(1.1);
}

.icon {
    cursor: pointer;
    font-size: 20px;
}

.circle {
    position: absolute;
    left: 20px;
    top: 5px;
    border-radius: 50px;
    background-color: var(--red);
    color: var(--white);
    font-weight: bold;
    padding: 3px 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
}