@font-face {
  font-display: swap;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;  
  src: local("Inter"), url("./Fonts/Inter/Inter-Regular.ttf") format("truetype");  
}

body {
  margin: 0;
  background-color: var(--background);
  font-family: "Inter" !important;
}

a {
  text-decoration: none !important;
  color: var(--contrast);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
  font-family: "Inter" !important;
}

input,
textarea {
  box-sizing: border-box;
  font-family: "Inter" !important;
}

::-webkit-scrollbar {
  display: block;
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--destaque);
  border-right: none;
  border-left: none;
}

::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 20px;
}

::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 20px;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--destaque) inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill {
  -webkit-text-fill-color: var(--text) !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: var(--texto1) !important;
  font-size: 16px !important;
}