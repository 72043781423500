.container {
    margin: 20px 0px 30px 0px;
}

.wrapper {
    
}

.title {
    margin: 0px 0px 0px 15px;
    font-size: 14px;
    color: var(--texto2);
    font-weight: bold;
}

.content {
    margin: 10px 0px;
}