.container {
    background-color: transparent;
    width: auto;
    display: inline-block;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    border: none;
}