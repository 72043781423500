.container {
    margin: 0px 25px;
}

.wrapper {
    flex: 3 0;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    animation: slide-in-wrapper 0.5s ease-in-out;
    margin-bottom: 30px;
    align-self: flex-start;
}

@keyframes slide-in-wrapper {
    0% {
        opacity: 0;
        transform: translateX(50%);
    }

    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}


.slideIn {
    animation: slide-in 1s ease-in-out;
}

.cards {
    min-width: 0px;
    flex-basis: calc(25% - 15px);
    transition: all 0.5s ease-in-out;
}

.filtersHeader {
    flex: 1 0;
    border-radius: 5px;
    padding: 20px;
    background-color: var(--destaque);
    align-self: flex-start;
    margin-bottom: 30px;
}

.filterTitle {
    color: var(--texto2);
    font-size: 12px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 5px;
}

.filterTitle svg {
    padding-left: 10px;
    font-size: 16px;
}

.filters {
    margin-top: 10px;
    display: flex;
    gap: 15px;
}

.filters div {
    flex: 1 0;
}

.filtersBlock {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: var(--background);
    padding: 15px;
    border-radius: 5px;
    align-self: flex-start;
}

.filterBlockTitle {
    color: var(--texto2);
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-right: 15px;
}

.filterBlockList {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: var(--destaque);
    top: calc(100% + 10px);
    left: 0px;
    right: 0px;
    z-index: 2;
    padding: 15px;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0px 5px 5px var(--background);
}

@media screen and (max-width: 767px) {
    .cards {
        min-width: 100%;
        flex-basis: 100%;
    }

    .wrapper {
        margin-bottom: 60px;
    }
}

@media screen and (max-width: 1024px) {

    .container {
        display: block;
    }

    .wrapper {
        margin-bottom: 60px;
    }

    .filters {
        flex-direction: column;
    }

    .slideIn {
        max-width: 100%;
    }

    .filtersBlock {
        width: calc(100% - 30px);
    }

}

@media screen and (max-width: 1440px) {
    .cards {
        flex-basis: calc(50% - 10px);
    }
}