.container {
    position: relative;
    background-color: var(--destaque);
    color: var(--texto1);
    box-shadow: 0px 0px 5px var(--background);
}

.expanded {
    background-color: var(--background) !important;
    border-radius: 5px;
}

.line {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    height: 1px;
    width: 100%;
    background-color: var(--border);
}