.container {}

.wrapper {
    display: flex;
    gap: 30px;
}

.infos {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    background-color: var(--destaque);
    border-radius: 5px;
    flex: 1 0;
    gap: 10px;
    padding: 10px 0px;
}


.block {
    background-color: var(--destaque);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.blockContent {
    display: flex;
    align-items: center;
    gap: 50px;
}

.blockName {
    font-size: 26px;
    font-weight: bold;
}

.blockHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
}

.blockTitle {
    color: var(--texto2);
    font-weight: bold;
    font-size: 12px;
    min-width: 100px;
}

.blockBody {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
    height: 100%;
}

.field {
    flex: 1 0;
    padding: 0px 5px;
    flex-basis: calc(50% - 15px);
    padding: 10px 5px;
}

.textarea {
    padding: 0px 5px;
    flex-basis: 100%
}

.command {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.hintIcon {
    margin-right: 5px;
    color: var(--texto2);
}

.hint {
    margin: 10px 0px 0px 0px;
    color: var(--texto2);
    font-size: 12px;
}


.text {
    flex: 1 0;
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    gap: 30px;
}

.what1 {
    width: 292px;
    height: 390px;
    border-radius: 20px;
}

.what2 {
    width: 268px;
    height: 268px;
    border-radius: 20px;
}

.bars {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.bar {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.barInfos {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}

.barValue {
    color: var(--texto2);
}

.barLine {
    width: 100%;
    height: 3px;
    background-color: var(--texto3);
    border-radius: 10px;
}

.barFill {
    background-color: var(--texto2);
    height: 3px;
    border-radius: 10px;
}

@media screen and (max-width: 768px) {

    .wrapper {
        flex-direction: column;
    }

    .infos {
        flex-direction: column;
        align-items: center;
    }

    .field {
        width: 100%;
    }

    .textarea {
        width: 100%;
    }

    .command {
        margin-bottom: 20px;
    }

    .blockContent {
        flex-direction: column;
    }

    .image {
        flex-direction: column;
    }

    .image :last-child {
        display: none;
    }

}