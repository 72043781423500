.container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
}

.menu {
    flex: 0 0;
    transition: left .5s ease;
    z-index: 2;
    position: fixed;
    bottom: 0px;
    top: 0px;
}

.content {
    flex: 1 0;
    padding: 0px 0px 0px 0px;
    transition: left .5s ease;
    position: absolute;
    right: 0px;
}

.hide {
    position: absolute;
    top: 15px;
    z-index: 3;
    transition: left .5s ease;
}

.chat {
    position: fixed;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: flex-end;
    border-radius: 50px;
    z-index: 5;
}

.chatAction {
    position: relative;
    cursor: pointer;
    margin: 20px 20px;
}

.chatHint {
    position: absolute;
    color: var(--texto1);
    width: 300px;
    right: 50px;
    top: -90px;
    right: 60px;
    padding: 20px 30px;
    border-radius: 20px 20px 2px 20px;
    font-size: 15px;
    background-color: var(--primary_main);
}

.chatBody {
    height: 75vh;
    width: 40vw;
    border-radius: 5px 0px 0px 0px;
}

@media screen and (max-width: 768px) {

    .chatAction {
        margin: 5px;
        padding: 3px;
        background-color: var(--primary_main);
        border-radius: 50px;
    }

    .chatHint {
        display: none;
    }

    .chat {
        left: 0px;
        flex-direction: column;
        align-items: center;
        background-color: var(--background);
        border-radius: 0px;
    }

    .chatBody {
        height: calc(100vh - 55px);
        width: 100vw;
        border-radius: 0px 0px 0px 0px;
    }
}