.container {
    width: 100%;
}

.wrapper {
    display: flex;
    gap: 30px;
    width: 100%;
}

.infos {
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex: 1 0;
}

.block {
    background-color: var(--destaque);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.blockHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
}

.blockTitle {
    color: var(--texto2);
    font-weight: bold;
    font-size: 12px;
    min-width: 100px;

}

.blockBody {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 10px;
}

.field {
    padding: 0px 5px;
    flex-basis: calc(50% - 15px);
    padding: 10px 0px;
}

.command {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 25px;
    font-size: 14px;
}

.commandLine {
    display: flex;
    gap: 15px;
    align-items: center;
}

.commandLineLabel {
    color: var(--texto2);
}

.commandLineValue {
    display: flex;
    gap: 15px;
    align-items: center;
}