.container {
    font-size: 14px;
}

.details {
    max-height: auto;
    padding: 0px 16px 18px 16px;
    margin-bottom: 10px;
    animation: expand 200ms ease;
    transform-origin: top;
}

@keyframes expand {
    from {
        transform: scaleY(0);
        max-height: 0px;
    }

    to {
        transform: scaleY(1);
        max-height: 100vh;
    }
}