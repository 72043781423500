.container {
    margin: 30px 0px;
}

.wrapper {
    padding: 0px 0px 10px 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.title {
    color: var(--texto1);
    font-weight: bold;
    font-size: 26px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.alert {
    color: var(--yellow);
    font-size: 12px;
}

.iconCheck {
    font-size: 18px;
    color: var(--green);
    border-radius: 50px;
    background-color: white;
}