.container {
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper {}

.block {
    background-color: var(--background);
    flex: 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0px 60px;
}

.title {
    font-size: 22px;
    font-weight: bold;
    color: var(--texto1)
}

.new {
    font-size: 14px;
    margin: 25px 0px;
    color: var(--texto1)
}

.newLink {
    color: var(--primary_main);
    cursor: pointer;
}

.field {
    width: 300px;
    padding: 10px 0px;
}

.forgot {
    font-size: 14px;
    color: var(--texto1);
    cursor: pointer;
    text-decoration: underline;
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
}

.button {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.or {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 10px;
}

.line {
    color: var(--texto2);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}


.line::before,
.line::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 43%;
    height: 1px;
    border-top: 1px dashed var(--border);
}

.line::before {
    transform: translateX(-65%);
}

.line::after {
    transform: translateX(65%);
}

.social {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

@media screen and (max-width: 768px) {

    .block {
        min-width: auto;
        max-width: auto;
        padding: 0px;
    }

    .field {
        width: 260px;
    }
}