.container {
    padding: 15px 25px 15px 0px;
    background-color: var(--background);
}

.wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
}

.star {
    color: #FFC000;
    cursor: pointer;
}

.promotion {
    color: var(--texto1);
    padding: 20px;
    font-size: 14px;
    width: 190px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.promotionTitle {
    font-weight: bold;
}

.promotionText {
    padding: 10px 0px;
}