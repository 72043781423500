.container {
    padding: 20px 0px 10px 0px;
}

.wrapper {
    color: var(--texto1);
    font-weight: bold;
    font-size: 15px;
}

.name {
    padding: 0px 20px;
}

.email {
    color: var(--texto2);
    font-weight: normal;
    padding: 10px 20px;
}

.options {
    padding: 0px 0px;

}

.options:before,
.options:after {
    margin: 10px 0px;
    display: block;
    content: '';
    height: 1px;
    background-color: var(--texto3);
}

.menu {
    cursor: pointer;
    padding: 10px 20px;
}

.menu:hover {
    background-color: var(--destaque) !important;
}