.container {
    width: 100%;
}

.wrapper {
    background: url('../../Images/circles.svg');
    background-size: cover;
}

.menu {
    z-index: 99;
    display: flex;
    padding: 20px 0px;
    justify-content: space-between;
    position: fixed;
    top: 0px;
    left: 12.5%;
    right: 12.5%;
    background-color: var(--background);
}

.menuIcon {
    width: 40px;
    height: 40px;
}

.menuItens {
    display: flex;
    gap: 25px;
}

.menuItem {}

.presentation {
    display: flex;
    align-items: center;
    gap: 20px;
    height: 100vh;
}

.presentationBlock {
    flex: 1 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.presentationBlockImage {
    flex: 1 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.presentationTitle {
    color: var(--texto1);
    font-size: 42px;
    font-weight: bold;
    line-height: 50px;
}

.presentationTitleHigh {
    color: var(--primary_main);
}

.presentationSub {
    color: var(--texto2);
    font-size: 16px;
    line-height: 28px;
    padding-bottom: 10px;
}

.presentationImage {
    width: 567px;
    height: 567px;
}

.content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contentWrapper {
    margin: auto;
    width: 75%;
}

.companies {
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    margin: 30px 0px 150px 0px;
}

.companiesTitle {
    color: var(--primary_light);
    margin-bottom: 20px;
}

.aboutInvert {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
}

.about {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutBlock {
    display: flex;
    flex-direction: column;
    flex: 1 0;
    gap: 20px;
}

.aboutBlockImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0;
    gap: 20px;
}

.aboutImage {
    width: 575px;
    height: 575px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aboutTitle {
    color: var(--texto1);
    font-size: 30px;
    font-weight: bold;
    line-height: 35px;
}

.aboutSub {
    color: var(--texto2);
    font-size: 16px;
    line-height: 28px;
    padding-bottom: 10px;
}

.aboutList {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.aboutListItem {
    display: flex;
    align-items: center;
    gap: 20px;
    color: var(--texto1);
}

.aboutListIcon {
    width: 20px;
    font-size: 20px;
    border-radius: 99px;
    padding: 10px;
    background: radial-gradient(circle at 0% 107%, var(--primary_dark) 0%, var(--primary_dark) 5%, var(--primary_dark) 45%, var(--primary_main) 60%, var(--primary_main) 90%);
}

.peoples {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    margin: 100px 0px;
    padding-bottom: 120px;
}

.peoplesTitle {
    color: var(--texto1);
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 50px;
}

.peoplesList {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.peoplesCard {
    flex: 1 0;
    border-radius: 5px;
    background-color: var(--destaque);
    padding: 40px;
    box-shadow: 0px 0px 15px var(--destaque);
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}

.peoplesCardText {
    margin-top: 25px;
    color: var(--texto1);
}

.peoplesCardName {
    color: var(--texto1);
    font-weight: bold;
}

.peoplesCardRole {
    margin-top: 5px;
    color: var(--texto2);
}

.pricing {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    margin: 100px 0px;
    padding-bottom: 120px;
}

.pricingTitle {
    color: var(--texto1);
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 50px;
}

.pricingList {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

.pricingCard {
    flex: 1 0;
    border-radius: 5px;
    background-color: var(--destaque);
    padding: 40px;
    box-shadow: 0px 0px 15px var(--destaque);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}

.pricingImage1 {
    margin-top: 50px;
    width: 173px;
    height: 200px;
}

.pricingImage2 {
    width: 223px;
    margin-top: 50px;
    height: 200px;
}

.pricingPlan {
    margin: 30px 0px 0px 0px;
    color: var(--texto1);
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}

.pricingSub {
    color: var(--texto2);
    min-height: 100px;
    text-align: center;
}

.pricingPrice {
    margin: 20px 0px;
    font-size: 26px;
    font-weight: bold;
    color: var(--texto1);
}

.pricingGet {
    width: 100%;
    color: var(--texto1);
    font-weight: bold;
    font-size: 18px;
}

.pricingGet::before {
    content: '';
    display: block;
    height: 1px;
    background-color: var(--texto2);
    margin-bottom: 50px;
}

.pricingGetList {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.pricingGetIcon {
    width: 16px;
    font-size: 16px;
    border-radius: 99px;
    padding: 6px;
    background: radial-gradient(circle at 0% 107%, var(--primary_dark) 0%, var(--primary_dark) 5%, var(--primary_dark) 45%, var(--primary_main) 60%, var(--primary_main) 90%);
}

.pricingGetItem {
    width: 100%;
    color: var(--texto1);
    display: flex;
    align-items: center;
    gap: 20px;
}

.footer {
    margin: 0px 0px 30px 0px;
}

.footer::before {
    content: '';
    display: block;
    height: 1px;
    background-color: var(--texto3);
    margin-bottom: 30px;
}

.footerWrapper {
    display: flex;
    justify-content: space-between;
}

.footerRights {
    color: var(--texto2);
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footerTerms {
    color: var(--texto2);
    font-size: 14px;
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
}

.footerTerms div {
    cursor: pointer;
}

.test {
    width: 100%;
    padding-bottom: 120px;
}

.testTitle {
    color: var(--texto1);
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 50px;
    text-align: center;
    padding-top: 80px;
}

@media screen and (max-width: 768px) {

    .menuAssist {
        display: none;
    }

    .menuRole {
        display: none;
    }


    .menu {
        padding: 20px 20px;
        left: 0px;
        right: 0px;
        justify-content: space-between;
        align-items: center;
    }

    .presentation {
        flex-direction: column-reverse;
        height: auto;
        margin-top: 90px;
    }

    .presentationImage {
        display: none;
    }

    .presentationTitle {
        font-size: 32px;
    }

    .aboutImage {
        width: 80%;
        height: 80%;
    }

    .footerWrapper {
        flex-direction: column;
        gap: 30px;
    }

    .footerRights {
        text-align: center;
    }

    .footerTerms {
        flex-direction: column;
    }

    .about,
    .aboutInvert {
        flex-direction: column;
        gap: 50px;
        height: auto;
        margin-top: 90px;
    }

    .peoples {
        height: auto;
        margin: 100px 0px 0px 0px;
    }

    .pricing {
        height: auto;
        margin: 0px 0px;
    }
}