.container {
    position: relative;
    padding: 16px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    gap: 5px;
}

.icon {
    font-size: 14px;
}