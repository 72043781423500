.container {
    width: 100%;
}

.container label {
    color: var(--texto1) !important;
}

.free {
    font-size: 26px;
    font-weight: bold;
    margin: 10px 0px 15px 0px;
}

.price {
    font-size: 20px;
    margin: 5px 0px 15px 0px;
}

.priceDescription {
    color: var(--texto1);
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
}

.priceDescriptionSub {
    margin-top: 10px;
    font-size: 16px;
}

.planHint {
    margin: 10px 0px;
    font-size: 12px;
    color: var(--texto2);
}

.loading {
    padding-top: 20px;
}

.loadingIcon {
    font-size: 28px;
    color: var(--primary_main);
    animation: spin 1.5s infinite linear;
}

.hint {
    font-size: 12px;
    margin-top: 10px;
    color: var(--red);
}

.highlight {
    color: var(--primary_main);
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}