.container {
    position: fixed;
    z-index: 999;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    background-color: var(--backgroundModal);
    cursor: default;
}

.wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.block {
    position: relative;
    border-radius: 5px;
    background-color: var(--destaque);
    padding: 25px;
    box-shadow: 0px 0px 20px var(--shadow);
}

.close {
    top: 10px;
    position: absolute;
    right: 20px;
}

@media screen and (max-width: 768px) {

    .wrapper {
        align-items: flex-start;
    }

}