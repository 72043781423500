.container {
    width: 100%;
    position: relative;
    border-radius: 5px;
    height: 100%;
}

.wrapper {
    padding: 0px 0px 0px 0px;
    display: flex;
    height: 100%;
}

.questionWrapper {
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: fill;
    height: calc(100vh - 75px);
}

.questionPopWrapper {
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: fill;
    height: 100%;
    border-radius: 5px;
    box-shadow: 0px 0px 15px var(--shadow);
}

.header {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    color: var(--texto1);
    font-size: 46px;
    font-weight: bold;
}

.color {
    color: var(--primary_main);
}

.layer {
    width: 100%;
    height: 100%;
    background-color: var(--background);
    border-radius: 5px;
}

.professionals {
    margin: 0px 0px 35px 0px;
}


/* --------- Historico --------- */

.historyContainer {
    flex: 1 0;
}

.historyWrapper {
    border-radius: 5px;
    background-color: var(--destaque);
    margin: 0px 20px 0px 20px;
    padding: 20px 30px 20px 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.historyTitle {
    color: var(--texto2);
    font-size: 12px;
    font-weight: bold;
}

.historyBody {
    padding: 2px 2px;
    max-height: calc(100vh - 170px);
    overflow: auto;
}

.historyHeader {
    font-size: 12px;
    font-weight: bold;
    color: var(--texto2);
    margin-bottom: 5px;
}

.historyQuestion {
    padding: 0px 0px 20px 0px;
}

.chatContainer {
    flex: 1 0;
    width: 100%;
    position: relative;
    overflow: auto;
    border-radius: 5px;
}

.chatWrapper {
    border-radius: 5px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    display: flex;
    flex-direction: column;
    background-color: var(--destaque);
}

.chatHeader {
    display: flex;
    gap: 20px;
    border-bottom: 1px solid var(--border);
    padding: 20px 30px;
}

.chatHeaderInfos {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
}


.chatHeaderInfosName {
    color: var(--texto1);
    font-weight: bold;
}

.chatHeaderInfosRole {
    color: var(--texto2);
    font-size: 14px;
}

.chatBody {
    display: flex;
    flex: 1 0;
    overflow: auto;
}

.chatScroll {
    padding: 30px 0px 5px 0px;
    display: flex;
    flex-direction: column;
    flex: 1 0;
    overflow: auto;
}

.chatScrollArea {
    flex: 1 0;
    padding: 0px 30px;
    max-height: 100%;
    overflow: auto;
}

.chatInput {
    border-top: 1px solid var(--border);
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px 30px 10px 30px;
    flex: 0 0;
}

.chatInputIcon {
    flex: 0 0;
    font-size: 20px;
    color: var(--texto2);
}

.chatInputText {
    flex: 1 0;
    display: flex;
    align-items: center;
}

.chatInputForm {
    flex: 1 0;
}

.chatInputButton {
    flex: 0 0;
}

.chatLine {
    gap: 30px;
    padding-bottom: 15px;
    margin-right: 80px;
}

.chatMessageName {
    color: var(--white);
    margin-bottom: 10px;
    margin-left: 0px;
    font-size: 12px;
}

.client {
    flex-direction: row-reverse;
    margin-right: 0px;
    margin-left: 80px;
}

.chatMessageWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.chatMessageSuggestion {
    overflow: auto;
    margin-bottom: 30px;
    margin-right: -80px;
    display: flex;
    gap: 10px;
    padding-bottom: 10px;
}

.suggestion {
    min-width: 50%;
}

.chatMessage {
    color: var(--texto1);
    padding: 20px;
    border-radius: 5px;
}

.chatDate {
    color: var(--texto2);
    font-size: 14px;
    margin: 0px 10px;
    display: flex;
    justify-content: flex-end;
}

.chatInfos {
    border-left: 1px solid var(--border);
    flex: 0 0;
}

.chatInfosHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 50px 100px;
    border-bottom: 1px solid var(--border);
}

.chatInfosHeaderName {
    margin-top: 15px;
    color: var(--texto1);
}

.chatInfosHeaderRole {
    color: var(--texto2);
    font-size: 14px;
    min-width: 125px;
    text-align: center;
}

.chatInfosBody {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.chatInfosBodyTitle {
    color: var(--texto2);
    font-weight: bold;
    padding: 20px 20px 0px 20px;
    font-size: 14px;
}

.chatInfosBodyContent {
    padding: 20px 30px 40px 30px;
}

.chatInfosBodyContentLine {
    color: var(--texto1);
    display: flex;
    gap: 15px;
    padding: 10px 0px;
}

.chatInfosBodyContentIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 20px;
    color: var(--texto3);
    font-size: 18px;
}

.loading {
    margin: 0px 20px;
}

.dotFlashing {
    position: relative;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: var(--primary_main);
    color: var(--primary_main);
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 0.5s;
}

.dotFlashing::before,
.dotFlashing::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
}

.dotFlashing::before {
    left: -15px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: var(--primary_main);
    color: var(--primary_main);
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
}

.dotFlashing::after {
    left: 15px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: var(--primary_main);
    color: var(--primary_main);
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
}

@keyframes dotFlashing {
    0% {
        background-color: var(--primary_main);
    }

    50%,
    100% {
        background-color: var(--texto1);
        ;
    }
}

.chatPromotion {
    background-color: var(--primary_main);
    color: var(--white);
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    flex: 0 0;
}

.chatPromotionText {
    padding: 20px 0px 20px 25px;
    flex: 1 0;
    width: 100%;
}

.chatPromotionClose {
    padding: 0px 25px 0px 0px;
    font-size: 20px;
    cursor: pointer;
    flex: 0 0;
}

@media screen and (max-width: 768px) {

    .wrapper {
        flex-direction: column;
        gap: 20px;
        align-items: center;
    }

    .historyContainer {
        width: 100%;
    }

    .historyWrapper {        
        margin: 0px 0px 20px 0px;
        border-radius: 0px;        
    }

    .chatContainer {
        min-height: calc(100vh - 80px);
        margin: 0px 0px 5px 0px !important;
        border-radius: 0px;
    }

    .chatWrapper {
        border-radius: 0px;
    }

    .chatInput {
        padding: 15px 10px 0px 15px;
        gap: 0px;
    }

    .chatLine {
        gap: 15px;
        margin-right: 10px;
        margin-left: 10px;
    }

    .chatMessageSuggestion {
        flex-direction: column;
        margin-right: 0px;
    }

    .suggestion {
        width: 100% !important;
    }

    .chatScroll {
        padding: 20px 0px 5px 0px;
    }

    .chatScrollArea {
        padding: 0px;
    }

    .layer,
    .container,
    .questionWrapper {
        border-radius: 0px;
    }

}