.container {
    cursor: pointer;
}

.filtersBlock {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: var(--background);
    padding: 15px;
    border-radius: 5px;
    align-self: flex-start;
}

.filterBlockTitle {
    color: var(--texto2);
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-right: 15px;
}

.filterBlockList {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: var(--destaque);
    top: calc(100% + 10px);
    left: 0px;
    right: 0px;
    z-index: 2;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0px 5px 5px var(--background);
    padding: 10px 0px;
}

.filterBlockItem {
    color: var(--texto1);
    min-height: 20px;
    padding: 10px 10px;
    cursor: pointer;
}

.filterBlockItem:hover {
    background-color: var(--background);
}