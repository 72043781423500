.light {
  --destaque: #F8F9FA;
  --background: #FFFFFF;
  --texto1: #212B36;
  --texto2: #637381;
  --texto3: #A0AEC0;
  --border: #b5c1d1;
  --backgroundOpac50: #ffffff7c;
  --backgroundOpac70: #9494947c;
  --backgroundOpac90: #FFFFFFE6;
}

.dark {
  --destaque: #212B36;
  --background: #161C24;
  --texto1: #FBFCFC;
  --texto2: #919EAB;
  --texto3: #454F5B;
  --border: #454F5B;
  --backgroundOpac50: #161c247a;
  --backgroundOpac70: #161c24b9;
  --backgroundOpac90: #161c24ef;
}

html {
  --white: #FFFFFF;
  --gray: #cacaca;
  --darkGray: #a3a3a3;
  --shadow: rgba(27, 27, 27, 0.494);
  --backgroundOpac: #161C2400;
  --backgroundModal: rgba(22, 28, 36, 0.5);
  --red: #ff3030;
  --error: #cf2f2f;
  --green: #00ab55;
  --yellow: #fda92d;
  --blue: #078dee;
  --opBlue: #078DEE;
  --opGreen: #00AB55;
  --opYellow: #fda92d;
  --opPurple: #7635dc;
  --opRed: #FF3030;
  --difuse: #0000005e;
}

.blue {
  --primary_lighter: #CCF4FE;
  --primary_light: #68CDF9;
  --primary_main: #078DEE;
  --primary_dark: #0351AB;
  --primary_darker: #012972;
  --transparent_foreground: #078dee29;
}

.green {
  --primary_lighter: #C8FACD;
  --primary_light: #5BE584;
  --primary_main: #00AB55;
  --primary_dark: #007B55;
  --primary_darker: #005249;
  --transparent_foreground: #35ee0729;
}

.yellow {
  --primary_lighter: #FEF4D4;
  --primary_light: #FED680;
  --primary_main: #fda92d;
  --primary_dark: #B66816;
  --primary_darker: #793908;
  --transparent_foreground: #eeb80729;
}

.purple {
  --primary_lighter: #EBD6FD;
  --primary_light: #B985F4;
  --primary_main: #7635dc;
  --primary_dark: #431A9E;
  --primary_darker: #200A69;
  --transparent_foreground: #9207ee29;
}

.red {
  --primary_lighter: #FFE3D5;
  --primary_light: #FFC1AC;
  --primary_main: #FF3030;
  --primary_dark: #B71833;
  --primary_darker: #7A0930;
  --transparent_foreground: #ee070729;
}