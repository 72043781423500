.container {
    margin-top: 20px;
    background-color: var(--texto1);
    border-radius: 5px;
    padding: 40px 30px;
}

.padding {
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading {
    font-size: 28px;
    color: var(--primary_main);
    animation: spin 1.5s infinite linear;
}

.infos {
    margin-top: 10px;
    font-size: 12px;
    color: #6d6e78;
}

.message {
    margin-top: 10px;
    font-size: 14px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}