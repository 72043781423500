.container {
    position: relative;
    border-radius: 5px;
    display: flex;
    justify-content: flex-end;
    min-height: 50px;
    min-width: 80vw;    
}

.wrapper {
    border-radius: 5px;
    position: absolute;
    padding: 15px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    color: var(--texto1);
    margin-bottom: 10px;
    gap: 20px;
    transition: all 0.3s ease-in-out;
    animation: slideInOut 0.3s;
}

@keyframes slideInOut {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(0);
    }
}



.information {
    display: flex;
    align-items: center;
    gap: 10px;
}

.informationIcon {
    font-size: 18px;
}

.close {
    cursor: pointer;
}