.container {}

.wrapper {
    border-radius: 50px;
    width: 30px;
    height: 15px;
    position: relative;
    padding: 2px;
    cursor: pointer;
}

.switch {
    position: absolute;
    border-radius: 50px;
    width: 15px;
    height: 15px;
    background-color: var(--texto1);
    transition: all 0.2s ease-in-out;
}